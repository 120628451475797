<template>
  <div class="container">
    <!-- 切换按钮 -->
    <div class="change">
      <div :class="activeted == 1 ? 'active' : ''" @click="change(1)">
        充值记录
      </div>
      <div :class="activeted == 2 ? 'active' : ''" @click="change(2)">
        兑换记录
      </div>
      <div :class="activeted == 3 ? 'active' : ''" @click="change(3)">
        账户明细
      </div>
    </div>
    <!-- 点击查看弹出订单号 -->
    <div class="showD" v-if="isShow">
      <span>订单号：</span>
      <div class="bottom">
        <span class="order">{{ order }}</span>
        <span class="copy" @click="copy(order)">复制</span>
        <span class="cancel el-icon-close" @click="cancel"></span>
      </div>
    </div>
    <!-- 遮罩层 -->
    <div class="zhe" v-show="isShow" @click="xiaoshi"></div>

    <!-- 数据表格 左边 充值记录-->
    <div class="table" v-show="activeted == 1">
      <div class="title">
        <ul>
          <li>日期</li>
          <li>充值金额</li>
          <li>充值结果</li>
          <li>订单号</li>
        </ul>
      </div>
      <div class="data">
        <ul v-for="(item, index) in tableData" :key="item.id">
          <li :class="index % 2 == 0 ? 'bg1' : 'bg2'">
            <div class="li first">
              <span>{{ item.endTime }}</span>
              <span>{{ item.firstTime }}</span>
            </div>
            <div class="li">
              <span>{{ item.money }}</span>
            </div>
            <div class="li">
              <span>{{ item.statusName }}</span>
            </div>
            <div class="li">
              <span @click="showD(item.id)" class="chakan">点击查看</span>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            layout="prev, pager, next"
            :total="tableData.total"
            background
            small
            @current-change="getPageNum"
            :current-page.sync="currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 数据表格 中间 兑换记录-->
    <div class="table" v-show="activeted == 2">
      <div class="title">
        <ul>
          <li>日期</li>
          <li>收支</li>
          <li>饰品名称</li>
        </ul>
      </div>
      <div class="data">
        <ul v-for="(item, index) in tableData1" :key="item.id">
          <li :class="index % 2 == 0 ? 'bg1' : 'bg2'">
            <div class="li first">
              <span>{{ item.endTime }}</span>
              <span>{{ item.firstTime }}</span>
            </div>
            <div class="li">
              <span>{{ item.price }}</span>
            </div>
            <div class="li end">
              <div class="name">{{ item.name }}</div>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            layout="prev, pager, next"
            :total="tableData1.total"
            background
            small
            @current-change="getPageNum"
            :current-page.sync="currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 数据表格 右边-->
    <div class="table" v-show="activeted == 3">
      <div class="title">
        <ul>
          <li>日期</li>
          <li>类型</li>
          <li>收支</li>
          <li>变动前</li>
          <li>变动后</li>
        </ul>
      </div>
      <div class="data">
        <ul v-for="(item, index) in tableData2" :key="item.id">
          <li :class="index % 2 == 0 ? 'bg1' : 'bg2'">
            <div class="li first">
              <span>{{ item.endTime }}</span>
              <span>{{ item.firstTime }}</span>
            </div>
            <div class="li">
              <span>{{ item.state }}</span>
            </div>
            <div class="li">
              <span>{{ item.amount }}</span>
            </div>
            <div class="li">
              <span>{{
                (
                  parseFloat(item.total_amount) - parseFloat(item.amount)
                ).toFixed(2)
              }}</span>
            </div>
            <div class="li">
              <span>{{ item.total_amount }}</span>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            layout="prev, pager, next"
            :total="tableData2.total"
            background
            small
            @current-change="getPageNum"
            :current-page.sync="currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前页数
      currentPage: 1,
      // 点击查看后显示的订单号
      order: "",
      // 控制显示哪个表格
      activeted: 1,
      // 控制点击查看按钮的状态
      isShow: false,
      activeName: "one",
      totalSize: 0,
      page: 1,
      pageSize: 10,
      totalSize1: 0,
      totalSize2: 0,
      totalSize3: 0,
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
    };
  },
  mounted() {
    this.getShoppList(this.page);
    this.getBalanceList(this.page);
    // this.getFenJieList(this.page);

    this.getTopupList(this.page);
    if (this.$route.query.type == "分解记录") {
      this.getFenJieList(this.page);
      this.activeName = "fore";
    }
    if (this.$route.query.type == "账户变动") {
      this.getBalanceList(this.page);
      this.getShoppList(this.page);
      this.activeName = "three";
    }
    if (this.$route.query.type == "兑换记录") {
      this.getBalanceList(this.page);
      this.getShoppList(this.page);
      this.activeName = "two";
    }
  },
  methods: {
    // 复制功能回调
    copy(content) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = content;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
    // 点解遮罩层
    xiaoshi() {
      this.isShow = false;
    },
    // 获取订单号
    getOrder(id) {
      this.order = this.tableData[this.tableData.length - 1].order_num;
      // console.log(this.order)
    },
    // 点击x干掉订单号框
    cancel() {
      this.isShow = false;
    },
    // // 按钮切换函数
    change(index) {
      this.currentPage = 1;
      this.activeted = index;
    },
    // 点击查看弹出订单号
    showD(index) {
      this.getOrder(index);
      this.isShow = true;
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
    },
    columnStyle1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
    },
    columnStyle2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 4) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
      if (columnIndex == 3) {
        return "color: #c3c3e2 ;";
      }

      if (columnIndex == 1) {
        if (row.pay == "支出") {
          return "color:  #c3c3e2 ;";
        } else {
          return "color:#02bf4d;";
        }
      }
    },
    columnStyle3({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 4) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #c3c3e2 ;";
      }
      if (columnIndex == 3) {
        return "color: #e9b10e ;";
      }

      if (columnIndex == 1) {
        // if (row.pay == "支出") {
        return "color:  #02bf4d ;";
        // } else {
        // 	return "color:#c3c3e2;";
        // }
      }
    },
    // 点击页码获取新的数据
    getPageNum(index) {
      this.page = index;
      if (this.activeted == 1) {
        this.getTopupList(this.page);
      } else if (this.activeted == 2) {
        this.getShoppList(this.page);
      } else if (this.activeted == 3) {
        this.getBalanceList(this.page);
      } else {
        this.getFenJieList(this.page);
      }
    },
    // 充值记录数据
    getTopupList(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/User/recharge", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            // 将日期分成两部分
            let timeData = data.data.list;
            for (let i = 0; i < timeData.length; i++) {
              let firstTime = timeData[i].create_time.substring(0, 10);
              let endTime = timeData[i].create_time.substring(11, 19);
              timeData[i].firstTime = firstTime;
              timeData[i].endTime = endTime;
            }
            timeData.total = data.data.total;
            this.tableData = timeData;

            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].mode == "zhifubao") {
                this.tableData[i].modeName = "支付宝";
              } else if (this.tableData[i].mode == "jdpay") {
                this.tableData[i].modeName = "支付宝";
              } else {
                this.tableData[i].modeName = "微信";
              }

              if (this.tableData[i].status == 1) {
                this.tableData[i].statusName = "未支付";
              } else if (this.tableData[i].status == 2) {
                this.tableData[i].statusName = "待支付";
              } else if (this.tableData[i].status == 3) {
                this.tableData[i].statusName = "支付成功";
              } else {
                this.tableData[i].statusName = "支付失败";
              }
            }
          }
        });
    },

    getShoppList(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/User/skinshistory1", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            // 将日期分成两部分
            let timeData = data.data.list;
            for (let i = 0; i < timeData.length; i++) {
              let firstTime = timeData[i].create_time.substring(0, 10);
              let endTime = timeData[i].create_time.substring(11, 19);
              timeData[i].firstTime = firstTime;
              timeData[i].endTime = endTime;
            }
            timeData.total = data.data.total;
            this.tableData1 = timeData;
          }
        });
    },
    getFenJieList(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/User/exchange_history", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize3 = data.data.total;
            this.tableData3 = data.data.skins_history;
          }
        });
    },
    getBalanceList(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/User/balanceDetail", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            // 将日期分成两部分
            let timeData = data.data.list;
            for (let i = 0; i < timeData.length; i++) {
              let firstTime = timeData[i].create_time.substring(0, 10);
              let endTime = timeData[i].create_time.substring(11, 19);
              timeData[i].firstTime = firstTime;
              timeData[i].endTime = endTime;
            }
            timeData.total = data.data.total;
            this.tableData2 = timeData;

            for (let i = 0; i < this.tableData2.length; i++) {
              if (Number(this.tableData2[i].amount) >= 0) {
                this.tableData2[i].pay = "收入";
              } else {
                this.tableData2[i].pay = "支出";
              }

              if (this.tableData2[i].type == 1) {
                this.tableData2[i].state = "皮肤兑换G币";
              } else if (this.tableData2[i].type == 2) {
                this.tableData2[i].state = "福利赠送";
              } else if (this.tableData2[i].type == 3) {
                this.tableData2[i].state = "充值";
              } else if (this.tableData2[i].type == 4) {
                this.tableData2[i].state = "对战失败";
              } else if (this.tableData2[i].type == 5) {
                this.tableData2[i].state = "对战平局";
              } else if (this.tableData2[i].type == 7) {
                this.tableData2[i].state = "幸运饰品失败";
              } else if (this.tableData2[i].type == 8) {
                this.tableData2[i].state = "cdk兑换";
              } else if (this.tableData2[i].type == 10) {
                this.tableData2[i].state = "下级充值推广佣金";
              } else if (this.tableData2[i].type == -1) {
                this.tableData2[i].state = "购买盲盒";
              } else if (this.tableData2[i].type == -2) {
                this.tableData2[i].state = "决战沙城";
              } else if (this.tableData2[i].type == -3) {
                this.tableData2[i].state = "幸运饰品";
              } else if (this.tableData2[i].type == -4) {
                this.tableData2[i].state = "商城购买饰品";
              } else if (this.tableData2[i].type == -6) {
                this.tableData2[i].state = "钻石兑换金币";
              } else if (this.tableData2[i].type == 6) {
                this.tableData2[i].state = "红包领取";
              } else if (this.tableData2[i].type == 11) {
                this.tableData2[i].state = "卡密充值";
              } else if (this.tableData2[i].type == 12) {
                this.tableData2[i].state = "签到福利";
              } else if (this.tableData2[i].type == 13) {
                this.tableData2[i].state = "宝箱任务";
              } else if (this.tableData2[i].type == 14) {
                this.tableData2[i].state = "对战任务";
              } else if (this.tableData2[i].type == 15) {
                this.tableData2[i].state = "充值任务";
              } else if (this.tableData2[i].type == 16) {
                this.tableData2[i].state = "注册赠送";
              } else if (this.tableData2[i].type == 17) {
                this.tableData2[i].state = "vip福利";
              } else if (this.tableData2[i].type == 18) {
                this.tableData2[i].state = "亏损补偿";
              } else if (this.tableData2[i].type == -7) {
                this.tableData2[i].state = "Roll房消耗";
              } else if (this.tableData2[i].type == 19) {
                this.tableData2[i].state = "Roll房退款";
              }
            }
          }
        });
    },

    //充值流水 页数
    currentChange(val) {
      this.getTopupList(val);
    },

    //玩家商城 分页
    currentChange1(val) {
      this.getShoppList(val);
    },

    //余额流水 分页
    currentChange2(val) {
      this.getBalanceList(val);
    },
    //余额流水 分页
    currentChange3(val) {
      this.getFenJieList(val);
    },

    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
html,
body {
  width: 100%;
  height: 100%;
}
.container {
  margin-top: -20px;
  background-color: #161932;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/bg-move.jpg) !important;
  background-size: 100% 100%;
  background-position: 0px 140px;
  // background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
  .change {
    width: 100%;
    height: auto;
    color: white;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    div {
      padding: 10px 20px;
      border-radius: 3px;
      box-sizing: border-box;
      border: 1px solid #9434d8;
      background-color: #1d213f;
      margin-top: 20px;
    }
    .active {
      background: linear-gradient(0deg, #9434d8 0%, #7822e5 100%) !important;
      border-image: linear-gradient(180deg, #a56fff, #7317d0) 1 1 !important;
    }
  }
  // 订单详情框样式
  .showD {
    z-index: 99999;
    width: 300px;
    height: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 15px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    background-color: #161932;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
    .bottom{
      display: flex;
    }
    .order {
      width: 70% !important;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .copy {
      padding: 2px 10px;
      background-color: #57169c;
      border-radius: 3px;
      margin-left: 5px;
    }
    .cancel {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  // 遮罩层样式
  .zhe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.795);
    z-index: 9999;
  }
  .table {
    margin-top: 20px;
    color: white;
    .title {
      background-color: #121429;
      ul {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: space-around;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        li {
          flex: 1;
          text-align: center;
        }
      }
    }
    .data {
      width: 100%;
      height: 500px;
      position: relative;
      // background-color: white;
      .bg1 {
        background-color: #121429;
      }
      .bg2 {
        background-color: #171a36;
      }
      ul {
        li {
          display: flex;
          width: 100%;
          height: 50px;
          //   background-color: red;
          .first {
            font-size: 12px !important;
          }
          .li {
            width: 25%;
            flex: 1;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              width: 100%;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .end {
            align-items: flex-start !important;
            .name {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    // 分页
    .pagination {
      position: absolute;
      left: 50%;
      top: 104%;
      transform: translate(-50%, 0);
    }
  }
}

/deep/ .el-pagination.is-background .el-pager li {
  border-radius: 6px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #30215f;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #30215f;
}
/deep/ .el-pagination .btn-next .btn-prev,
.el-pagination .btn-prev .el-icon {
  background-color: #333542;
}
/deep/ .el-pager li.active,
.el-pager {
  color: white !important;
}
.chakan {
  color: #af6bf8;
}
</style>