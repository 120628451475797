<template>
  <div :class="[showNav ? 'win' : 'winm']">
    <div
      :class="{
        'win-con': winList.skins_info.length == 1,
        'win-con1': winList.skins_info.length == 2,
        'win-con2': winList.skins_info.length == 3,
        'win-con3': winList.skins_info.length == 4,
        'win-con4': winList.skins_info.length == 5,
      }"
      v-if="showNav"
    >
      <ul class="enter-center">
        <li v-for="(item, index) in winList.skins_info" :key="index">
          <div class="win-warp pc">
            <!-- <div class="win-textt"> -->
            <!-- <span><img src="../assets/img/masonry.png" -->
            <!-- style="width: 16px; margin-right: 2px" />{{ item.price }}</span> -->
            <!-- <span @click="winexchange(index)" v-if="!item.ccc">分解</span> -->
            <!-- <span @click="winexchange(99)" style="display: none;" v-else></span> -->
            <!-- </div> -->
            <div
              class="win-img"
              :style="{
                backgroundImage: 'url(' + getBackZuijin(item) + ')',
              }"
            >
              <img :src="item.img" />
            </div>
            <div class="win-texts">
              {{ extractValuesInParentheses(item.name) }}
            </div>
            <div class="win-text" :title="item.name">
              {{ getFrist(item.name) }} | {{ getLast(item.name) }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      <ul v-if="winLists == 1" class="win-conm">
        <li v-for="(item, index) in winList.skins_info" :key="index">
          <div
            class="win-warp"
            :style="{
              backgroundImage: 'url(' + getBackZuijin(item) + ')',
            }"
          >
            <div class="win-textt">
              <!-- <span><img src="../assets/img/masonry.png" -->
              <!-- style="width: 16px; margin-right: 2px" />{{ item.price }}</span> -->
              <!-- <span @click="winexchange(index)" v-if="!item.ccc">分解</span> -->
              <!-- <span @click="winexchange(99)" style="display: none;" v-else></span> -->
            </div>
            <div class="win-img">
              <img :src="item.img" />
            </div>
            <div class="win-texts">
              {{ extractValuesInParentheses(item.name) }}
            </div>
            <div class="win-text" :title="item.name">
              {{ getFrist(item.name) }} | {{ getLast(item.name) }}
            </div>
          </div>
        </li>
        <div class="win-bot2" v-if="winLists == 1">
          <div class="win-span2" @click="winget">放入背包</div>
          <div
            class="win-span1"
            style="margin-left: 12px"
            @click="winexchange('99')"
            v-if="!isis && !winList.skins_info[0].ccc"
          >
            <img src="../assets/img/masonry.png" style="width: 20px" /><span>{{
              total_price
            }}</span>
            <span>(分解)</span>
          </div>
        </div>
      </ul>
      <ul v-else>
        <li v-for="(item, index) in winList.skins_info" :key="index">
          <div class="win-warp">
            <div class="top">
              <div
                class="win-img"
                :style="{
                  backgroundImage: 'url(' + getBackZuijin(item) + ')',
                }"
              >
                <img :src="item.img" />
              </div>
              <div class="right">
                <div class="win-texts">
                  {{ extractValuesInParentheses(item.name) }}
                </div>
                <div class="win-text" :title="item.name">
                  {{ getFrist(item.name) }} |
                  {{ getLast(item.name) }}
                </div>
              </div>
            </div>
            <div class="win-textt">
              <span
                ><img
                  src="../assets/img/masonry.png"
                  style="width: 16px; margin-right: 2px"
                />{{ item.price }}</span
              >
              <span @click="winexchange(index)">分解</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="win-bot" v-if="showNav">
      <div class="win-span2" @click="winget">放入背包</div>
      <div
        class="win-span1"
        @click="winexchange('99')"
        v-if="!isis && !winList.skins_info[0].ccc"
      >
        <img src="../assets/img/masonry.png" style="width: 20px" /><span>{{
          total_price
        }}</span>
        <span>(分解)</span>
      </div>
    </div>
    <div class="win-bot" v-if="!showNav && winLists != 1">
      <div class="win-span2" @click="winget">放入背包</div>
      <div
        class="win-span1"
        style="margin-left: 12px"
        @click="winexchange('99')"
        v-if="!isis && !winList.skins_info[0].ccc"
      >
        <img src="../assets/img/masonry.png" style="width: 20px" /><span>{{
          total_price
        }}</span>
        <span>(分解)</span>
      </div>
    </div>
    <!-- <div class="win-x" @click="winX">X</div> -->
    <!-- <div class="win-back">
			<img src="../assets/img/win3.png" />
		</div> -->
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
export default {
  props: ["winList", "winState", "player_skins_ids", "isis"],
  data() {
    return {
      //winState: true,
      list4: [],
      total_price: 0,
      windowWidth: document.body.clientWidth,
      showNav: true,
      winLists: 0,
    };
  },
  methods: {
    extractValuesInParentheses(input) {
      const regex = /\((.*?)\)/g;
      const matches = [];
      let match;

      while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
      }

      return matches[0];
    },
    getFrist(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[0];
    },
    getLast(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[1];
    },
    splitAndRemoveParentheses(input) {
      const parts = input.split("|");
      const result = parts.map((part) => {
        const indexOfParenthesis = part.indexOf("(");
        if (indexOfParenthesis !== -1) {
          return part.substring(0, indexOfParenthesis).trim();
        }
        return part.trim();
      });
      return result;
    },
    getBackZuijin(e) {
      if (e.color == "#007acc") {
        e.background = require("../assets/img/openBox/4.png");
      } else if (e.color == "#FF4500") {
        // 红
        e.background = require("../assets/img/openBox/2.png");
      } else if (e.color == "#8a2be2") {
        // 粉
        e.background = require("../assets/img/openBox/3.png");
      } else if (e.color == "#FFA500") {
        // 金
        e.background = require("../assets/img/openBox/1.png");
      }
      return e.background;
    },
    //兑换
    winexchange(index) {
      var param = {
        player_skins_ids: this.winList.player_skins_ids,
      };
      if (index != "99") {
        param = {
          player_skins_ids: [this.winList.player_skins_ids[index]],
        };
      } else {
        this.$emit("winexchange", false);
      }
      this.$axios
        .post("/index/User/exchangeToMoney", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              //message: data.msg,
              message: "兑换成功",
              type: "success",
            });
            if (index != "99") {
              this.winList.skins_info = this.winList.skins_info.filter(
                (item, indexs) => indexs != index
              );
              var a = this.winList.player_skins_ids.splice(
                this.winList.player_skins_ids.indexOf(
                  this.winList.player_skins_ids[index]
                ),
                1
              );
              this.total_price = 0;
              for (let i = 0; i < this.winList.skins_info.length; i++) {
                this.total_price += parseFloat(
                  this.winList.skins_info[i].price
                );
              }
              this.total_price = this.total_price.toFixed(2);
              if (this.winList.player_skins_ids.length == 0) {
                this.$emit("winexchange", false);
              }
            }
          } else {
            this.$message({
              //message: data.msg,
              message: "兑换失败",
              type: "warning",
            });
          }
        });
    },
    //放入背包
    winget() {
      this.$emit("winget", false);
    },
    //x掉
    winX() {
      this.$emit("winX", false);
    },
  },
  mounted() {
    this.winLists = this.winList.skins_info.length;
    for (let i = 0; i < this.winList.skins_info.length; i++) {
      this.total_price += Number(this.winList.skins_info[i].price);
    }
    console.log(this.winList);
    this.total_price = this.total_price.toFixed(2);
    if (this.windowWidth < 1024) {
      this.showNav = false;
    }
  },
};
</script>

<style lang="less" scoped>
.enter-center {
  display: flex;
  justify-content: center;
}
.win_container {
  display: grid !important;
  gap: 5px 10px;
  overflow: auto;
  height: 320px;
  overflow-x: hidden;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;

  li {
    width: 100%;
  }

  .win-warp {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.winm {
  width: 100vw !important;
  padding: 0 !important;
  // background-color: #1c2230;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;

  .win-back {
    width: 100%;
    height: auto;
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -10;
    animation: move 5s linear infinite;

    img {
      width: 100%;
      height: auto;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;

    li {
      width: 100%;

      .win-warp {
        width: 100%;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        .top {
          display: flex;
          align-items: center;
        }
        .win-img {
          padding: 20px 0;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          img {
            width: 100%;
            height: auto;
            // max-height: auto;
            height: 70px;
            min-height: 70px;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .right {
          margin-left: 12px;
        }

        .win-text {
          // text-overflow: ellipsis;
          // text-align: center;
          // margin-left: auto;
          // margin-right: auto;
          // bottom: 30px;
          // font-size: 12px;
          // color: rgba(255, 255, 255, 0.59);
          // margin-top: 6px;
        }

        .win-texts {
          color: #80c00c;
          font-weight: 600;
        }

        .win-textt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 95%;
          margin: -10px auto;
          color: white;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;

          span {
            display: flex;
            align-items: center;
            font-size: 12px !important;
            font-family: "微软雅黑";

            img {
              margin-right: 10px !important;
            }
          }

          span:last-child {
            display: block;
            padding: 6px 24px !important;
            border-radius: 5px;
            background-color: #649907;
          }
        }
      }
    }
  }

  .win-conm {
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 80% !important;

    li:first-child {
      margin-left: 0;
    }

    li {
      width: 100% !important;
      height: auto;

      .win-warp {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        height: auto !important;
        background-size: 100% 100%;
        padding: 32px 0;

        .win-img {
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;

          img {
            width: 70%;
            height: 100%;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.59);
          margin-top: 10px;
        }

        .win-texts {
          text-align: center;
          color: #80c00c;
        }

        .win-textt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 6px 0;
          color: white;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;

          span {
            display: flex;
            align-items: center;
            font-size: 12px !important;
            font-family: "微软雅黑";

            img {
              margin-right: 10px !important;
            }
          }

          span:last-child {
            display: block;
            padding: 6px 24px !important;
            border-radius: 5px;
            background-color: #649907;
          }
        }
      }
    }

    .win-bot2 {
      color: white;
      text-align: center;
      font-family: "微软雅黑";
      display: flex;
      width: 90%;
      margin: 30px auto;

      .win-span1 {
        background-color: hsla(0, 0%, 100%, 0.5);
        border-radius: 4px;
        margin: 0 auto;
        margin-bottom: 0px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 15px;
        font-size: 14px;
        width: 200px;
        text-shadow: 1px 1px 1px #999;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 15px;
        }

        span:last-child {
          margin-left: 5px;
          color: red;
        }

        img {
          margin-right: 5px;
          width: 15px !important;
          height: 15px;
        }
      }

      .win-span1:hover {
        cursor: pointer;
      }

      .win-span2 {
        background-color: #80c00c;
        border-radius: 4px;
        margin: 0 auto;
        // margin-left: 12px;
        margin-bottom: 0px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 15px;
        font-size: 14px;
        width: 200px;
        text-shadow: 1px 1px 1px #999;
      }

      .win-span2:hover {
        cursor: pointer;
      }
    }
  }

  .win-bot {
    color: white;
    text-align: center;
    font-family: "微软雅黑";
    display: flex;
    width: 90%;
    margin: 30px auto;

    .win-span1 {
      background-color: hsla(0, 0%, 100%, 0.5);
      border-radius: 4px;
      margin: 0 auto;
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      width: 200px;
      text-shadow: 1px 1px 1px #999;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 15px;
      }

      span:last-child {
        margin-left: 5px;
        color: red;
      }

      img {
        margin-right: 5px;
        width: 15px !important;
        height: 15px;
      }
    }

    .win-span1:hover {
      cursor: pointer;
    }

    .win-span2 {
      background-color: #80c00c;
      border-radius: 4px;
      margin: 0 auto;
      margin-left: 12px;
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      width: 200px;
      text-shadow: 1px 1px 1px #999;
    }

    .win-span2:hover {
      cursor: pointer;
    }
  }
}

.win {
  max-width: 1440px;
  padding: 20px;
  // background-color: #1c2230;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;

  // padding-top: 0;
  .win-back {
    width: 100%;
    height: auto;
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -10;
    animation: move 5s linear infinite;

    img {
      width: 100%;
      height: auto;
    }
  }

  ul {
    margin: auto;
    display: flex;
    justify-content: space-between;

    li:first-child {
      margin-left: 0;
    }

    li {
      width: 240px;
      margin-left: 50px;

      .win-warp {
        // width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;

        .win-img {
          padding: 30px 0;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: auto;
            max-height: 150px;
            height: 150px;
            min-height: 150px;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.59);
          margin-top: 10px;
        }

        .win-texts {
          text-align: center;
          color: #80c00c;
        }

        .win-textt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 6px 0;
          color: white;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;

          span {
            display: flex;
            align-items: center;
            font-size: 12px !important;
            font-family: "微软雅黑";

            img {
              margin-right: 10px !important;
            }
          }

          span:last-child {
            display: block;
            padding: 6px 24px !important;
            border-radius: 5px;
            background-color: #649907;
          }
        }
      }
    }
  }

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .win-top {
    width: 100%;

    display: flex;
    justify-content: center;

    img {
      width: 50%;
      // height: 200px;
    }
  }

  .win-x {
    position: absolute;
    top: -20px;
    right: 20px;
    color: #e9b10e;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #e9b10e;
    text-align: center;
    line-height: 20px;
  }

  .win-x:hover {
    cursor: pointer;
  }

  .win-bot {
    margin-top: 54px;
    color: white;
    text-align: center;
    font-family: "微软雅黑";

    .win-span1 {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 4px;
      margin: 0 auto;
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      width: 200px;
      text-shadow: 1px 1px 1px #999;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 15px;
      }

      span:last-child {
        margin-left: 5px;
        color: red;
      }

      img {
        margin-right: 5px;
        width: 20px !important;
        height: 20px;
      }
    }

    .win-span1:hover {
      cursor: pointer;
    }

    .win-span2 {
      background-color: #80c00c;
      border-radius: 4px;
      margin: 0 auto;
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      width: 200px;
      text-shadow: 1px 1px 1px #999;
    }

    .win-span2:hover {
      cursor: pointer;
    }
  }

  .win-con {
	width: 180px;
    display: flex;
    justify-content: center;

    ul {
      width: 100%;
      display: flex;
      justify-content: center;

      li {
        width: 100%;
        border-radius: 5px;
        // overflow: hidden;

        .win-warp {
          width: 100%;
          // background-color: #e2c873;
          position: relative;
          cursor: pointer;
		  display: flex;
			justify-content: center;
			margin-left: 13px;
		// margin-left: -110px;
          .win-img {
			width: 100%;
            padding: 10px;
            background-image: url("../assets/img/box-skins-blue.jpg");
            background-size: 100% 100%;
			
            img {
              width: 100% !important;
              height: auto !important;
            }
          }

          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }

          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }

          .win-text {
            text-overflow: ellipsis;
            position: absolute;
            width: 100px;
            text-align: center;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: 30px;
            font-size: 10px;
            color: white;
          }

          .win-textt {
            display: none;
            width: 100%;
            padding: 6px 0;
            background-color: #e9b10e;
            background-size: 100% 100%;
            border: 1px solid #ffea95;
            color: #000000;
            // padding: 10px;
            //overflow: hidden;
            //white-space: nowrap;
            text-overflow: ellipsis;
            color: #000;
            font-size: 10px;
          }
        }
      }
    }
  }

//   .win-con1 {
//   	display: flex;
//   	justify-content: center;

//   	ul {
//   		margin: -5px;
//   		width: 80%;
//   		display: flex;
//   		flex-flow: row wrap;

//   		//flex-wrap: wrap;
//   		li {
//   			width: 50%;
//   			border-radius: 5px;
//   			margin: 5px 0;

//   			.win-warp {
//   				width: 100%;
//   				position: relative;
//   				cursor: pointer;

//   				.win-img {
//   					padding: 10px;
//   					background-image: url("../assets/img/box-skins-blue.jpg");
//   					background-size: 100% 100%;

//   					img {
//   						width: 100%;
//   						height: auto;
//   					}
//   				}

//   				.win-img1 {
//   					background-image: url("../assets/img/box-skins-violet.jpg");
//   				}

//   				.win-img2 {
//   					background-image: url("../assets/img/box-skins-golden.jpg");
//   				}

//   				.win-text {
//   					text-overflow: ellipsis;
//   					position: absolute;
//   					width: 100px;
//   					text-align: center;
//   					left: 0;
//   					right: 0;
//   					margin-left: auto;
//   					margin-right: auto;
//   					bottom: 30px;
//   					font-size: 10px;
//   					color: white;
//   				}

//   				.win-textt {
//   					width: 100%;
//   					padding: 6px 0;
//   					background-color: #e9b10e;
//   					background-size: 100% 100%;
//   					border: 1px solid #ffea95;
//   					color: #000000;
//   					// padding: 10px;
//   					//overflow: hidden;
//   					//white-space: nowrap;
//   					text-overflow: ellipsis;
//   					font-size: 10px;
//   				}
//   			}
//   		}
//   	}
//   }

//   .win-con2 {
//   	display: flex;
//   	justify-content: center;

//   	ul {
//   		margin: -5px;
//   		width: 80%;
//   		display: flex;
// 		justify-content: center;
//   		// flex-flow: row wrap;

//   		li {
//   			margin-top: 5px;
//   			overflow: hidden;
//   			width: 50%;
//   			width: 50%;
//   			border-radius: 5px;
//   			// background-color: #e2c873;
//   			margin: 5px 0;

//   			.win-warp {
//   				border-radius: 5px;
//   				overflow: hidden;
//   				width: 100%;
// 				height: auto;
//   				cursor: pointer;
//   				position: relative;

//   				.win-img {
// 					width: 100% !important;
// 					height: auto;
//   					// padding: 10px;
//   					background-image: url("../assets/img/box-skins-blue.jpg");
//   					background-size: 100% 100%;

//   					img {
//   						border-top-right-radius: 5px;
//   						width: 100%;
//   						height: auto;
//   					}
//   				}

//   				.win-img1 {
//   					background-image: url("../assets/img/box-skins-violet.jpg");
//   				}

//   				.win-img2 {
//   					background-image: url("../assets/img/box-skins-golden.jpg");
//   				}

//   				.win-text {
//   					text-overflow: ellipsis;
//   					position: absolute;
//   					width: 100px;
//   					text-align: center;
//   					left: 0;
//   					right: 0;
//   					margin-left: auto;
//   					margin-right: auto;
//   					bottom: 30px;
//   					font-size: 10px;
//   					color: white;
//   				}

//   				.win-textt {
//   					width: 100%;
//   					padding: 6px 0;
//   					background-color: #e9b10e;
//   					background-size: 100% 100%;
//   					border: 1px solid #ffea95;
//   					color: #000000;
//   					// padding: 10px;
//   					//overflow: hidden;
//   					//white-space: nowrap;
//   					text-overflow: ellipsis;
//   					font-size: 10px;
//   				}
//   			}
//   		}
//   	}
//   }

  // .win-con3 {
  // 	display: flex;
  // 	justify-content: center;

  // 	ul {
  // 		margin: -5px;
  // 		width: 80%;
  // 		display: flex;
  // 		flex-flow: row wrap;

  // 		li {
  // 			// margin: 5px;
  // 			min-width: 50%;
  // 			max-width: 50%;
  // 			margin: 5px 0;

  // 			.win-warp {
  // 				border-radius: 5px;
  // 				overflow: hidden;
  // 				// width: 100%;
  // 				cursor: pointer;
  // 				position: relative;

  // 				.win-img {
  // 					padding: 10px;
  // 					background-image: url("../assets/img/box-skins-blue.jpg");
  // 					background-size: 100% 100%;

  // 					img {
  // 						width: 100%;
  // 						height: auto;
  // 					}
  // 				}

  // 				.win-img1 {
  // 					background-image: url("../assets/img/box-skins-violet.jpg");
  // 				}

  // 				.win-img2 {
  // 					background-image: url("../assets/img/box-skins-golden.jpg");
  // 				}

  // 				.win-text {
  // 					text-overflow: ellipsis;
  // 					position: absolute;
  // 					width: 100px;
  // 					text-align: center;
  // 					left: 0;
  // 					right: 0;
  // 					margin-left: auto;
  // 					margin-right: auto;
  // 					bottom: 30px;
  // 					font-size: 10px;
  // 					color: white;
  // 				}

  // 				.win-textt {
  // 					width: 100%;
  // 					padding: 6px 0;
  // 					background-color: #e9b10e;
  // 					background-size: 100% 100%;
  // 					border: 1px solid #ffea95;
  // 					color: #000000;
  // 					// padding: 10px;
  // 					//overflow: hidden;
  // 					//white-space: nowrap;
  // 					text-overflow: ellipsis;
  // 					font-size: 10px;
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  // .win-con4 {
  // 	display: flex;
  // 	justify-content: center;

  // 	ul {
  // 		margin: -5px;
  // 		width: 80%;
  // 		display: flex;
  // 		flex-flow: row wrap;

  // 		li {
  // 			// margin: 5px;
  // 			margin: 5px 0;
  // 			min-width: 50%;
  // 			max-width: 50%;

  // 			.win-warp {
  // 				border-radius: 5px;
  // 				overflow: hidden;
  // 				// width: 100%;
  // 				position: relative;
  // 				cursor: pointer;

  // 				.win-img {
  // 					padding: 10px;
  // 					background-image: url("../assets/img/box-skins-blue.jpg");
  // 					background-size: 100% 100%;

  // 					img {
  // 						width: 100%;
  // 						height: auto;
  // 					}
  // 				}

  // 				.win-img1 {
  // 					background-image: url("../assets/img/box-skins-violet.jpg");
  // 				}

  // 				.win-img2 {
  // 					background-image: url("../assets/img/box-skins-golden.jpg");
  // 				}

  // 				.win-text {
  // 					text-overflow: ellipsis;
  // 					position: absolute;
  // 					width: 100px;
  // 					text-align: center;
  // 					left: 0;
  // 					right: 0;
  // 					margin-left: auto;
  // 					margin-right: auto;
  // 					bottom: 30px;
  // 					font-size: 10px;
  // 					color: white;
  // 				}

  // 				.win-textt {
  // 					width: 100%;
  // 					padding: 6px 0;
  // 					// padding: 10px;
  // 					//overflow: hidden;
  // 					//white-space: nowrap;
  // 					text-overflow: ellipsis;
  // 					background-color: #e9b10e;
  // 					background-size: 100% 100%;
  // 					border: 1px solid #ffea95;
  // 					color: #000000;

  // 					font-size: 10px;
  // 				}
  // 			}
  // 		}

  // 		li:nth-child(1) {
  // 			min-width: 45%;
  // 			max-width: 45%;
  // 			margin-left: 25%;
  // 		}

  // 		li:nth-child(2) {
  // 			min-width: 50%;
  // 			max-width: 50%;
  // 		}
  // 	}
  // }
}

// .win-conm {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				cursor: pointer;
// 				position: relative;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100%;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					bottom: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con1m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				cursor: pointer;
// 				position: relative;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100px;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con2m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				position: relative;
// 				cursor: pointer;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100px;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con3m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			margin: 5px 0;

// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				position: relative;
// 				cursor: pointer;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100px;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con4m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {

// 			// margin: 5px;
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				cursor: pointer;
// 				position: relative;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					text-align: center;
// 					left: 0px;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 					transform: scale(0.8);
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

@media screen and (max-width: 767px) {
  .kai-box {
    padding: 0 !important;
  }

  .win-box {
    left: 0 !important;
    margin-left: 0 !important;
  }

  .win-box {
    z-index: 99999999999;

    // .win {
    // 	width: 85vw !important;
    // 	left: 0vw;
    // 	top: 0vh;
    // 	// transform: translateX(-50%);
    // 	border-radius: 10px;

    // 	.win-text {
    // 		font-size: 12px;
    // 		overflow: hidden;
    // 		text-overflow: ellipsis;
    // 		white-space: nowrap;
    // 	}
    // }
  }
}
.pc {
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  .win-img{
    box-sizing: border-box;
    width: 200px !important;
    height: 200px !important;
  }
  img {
    width: 100% !important;
    height: auto !important;
    margin-left: 0 !important;
  }
}
</style>