<template>
  <div>
    <!-- title -->
    <div class="header">
      <span>邀请奖励</span>
    </div>
    <div class="spread">
      <!-- <navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader> -->
      <!-- <div class="spread-warp">
      <div class="sel">
        <div class="spread-input1">
          <div class="input-con">
            <div class="input-top">我的邀请链接:</div>
            <div class="input-bot">
              <input type="text" :value="valueUrl" disabled />
              <span @click="copyText(valueUrl)">复制</span>
            </div>
          </div>
          <div class="input-con input-con1">
            <div class="input-top">我的邀请码:</div>
            <div class="input-bot">
              <input
                type="text"
                :value="valueCode"
                style="width: 200px"
                disabled
              />
              <span @click="copyText(valueCode)">复制</span>
            </div>
          </div>
        </div>
        <div class="sel-bot">
          <ul>
            <li>
              <div class="selbot-name">我邀请的人数:</div>
              <div class="selbot-img">
                <img src="../../../assets/img/sp1.png" /><span>{{
                  value1
                }}</span>
              </div>
            </li>
            <li>
              <div class="selbot-name">总充值:</div>
              <div class="selbot-img">
                <img src="../../../assets/img/sp3.png" /><span>{{
                  value3 / (value2 / 10)
                }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="list">
        <el-table
          :data="
            tableData1
          "
          style="width: 100%"
          class="list-table"
        >
          <el-table-column prop="name" label="用户名" width="180">
          </el-table-column>
          <el-table-column prop="recharge" label="充值金额" width="180">
          </el-table-column>
          <el-table-column prop="ratio" label="奖励比例" width="180">
          </el-table-column>
          <el-table-column prop="money" label="邀请奖励" width="180">
          </el-table-column>
          <el-table-column prop="time" label="充值时间" width="180">
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
		  style="margin-top: 20px;"
        >
        </el-pagination>
      </div>
    </div> -->

      <!-- 上方邀请码、链接 -->
      <div class="top">
        <!-- 邀请码 -->
        <div class="top-left">
          <div class="myInvite">我的邀请码</div>
          <div class="inviteInput">
            <input type="text" v-model="valueCode" />
            <div class="copy">
              <span @click="copyText(valueCode)">复制</span>
              <img src="../../../assets/img/mes-copy.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 推广链接 -->
        <div class="top-center">
          <div class="myGoout">推广链接</div>
          <div class="gooutInput">
            <input type="text" v-model="valueUrl" />
            <div class="copy">
              <span @click="copyText(valueUrl)">复制</span>
              <img src="../../../assets/img/mes-copy.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 基本数据 -->
        <div class="top-right">
          <div class="num">
            <img src="../../../assets/img/num.png" alt="" />
            <span>邀请人数： {{ value1 }}</span>
          </div>
          <div class="awart">
            <img src="../../../assets/img/awart.png" alt="" />
            <span>累计充值：{{ value3 }}</span>
          </div>
          <div class="proportion">
            <img src="../../../assets/img/proporation.png" alt="" />
            <span>充值收益：{{ value2 }}%</span>
          </div>
          <div class="proportion">
            <img src="../../../assets/img/proporation.png" alt="" />
            <span>消耗收益：{{ value4 }}%</span>
          </div>
        </div>
      </div>
      <!-- 中间数据展示部分 -->
      <div class="content">
        <!-- 切换按钮 -->
        <div class="change">
          <span :class="activated==1 ? 'active' : ''" @click="change(1)"
            >邀请记录</span
          >
          <span :class="activated==2 ? 'active' : ''" id="right" @click="change(2)"
            >充值奖励</span
          >
          <span :class="activated==3 ? 'active' : ''" id="right" @click="change(3)"
            >消耗奖励</span
          >
        </div>
        <!-- 邀请记录 -->
        <div class="left stys" v-if="activated==1">
          <div class="title">
            <ul class="ul">
              <li>用户名</li>
              <li>累计充值</li>
              <li>累计流水</li>
              <li>绑定方式</li>
              <li>绑定时间</li>
            </ul>
            <div class="cont">
              <ul v-for="item in tableData1" :key="item.id">
                <li>{{ item.name }}</li>
                <li>{{ item.recharge }}</li>
                <li>{{ item.total_amount }}</li>
                <li>{{ item.source }}</li>
                <li>
                  <span class="time">{{ item.endTime }}</span>
                  <span class="time">{{ item.firstTime }}</span>
                </li>
              </ul>
            </div>
            <div class="pagination">
              <el-pagination
                layout="prev, pager, next"
                :total="tableData1.total"
                @current-change="getPageNum1"
            :current-page.sync="currentPage"
                background
                small
                :page-size='5'
              >
              </el-pagination>
            </div>
            <div class="total">
              <div class="s1">总充值：<span>{{ allmoney }}</span></div>
              <div class="s2">总消耗：<span>{{ allusemoney }}</span></div>
            </div>
          </div>
        </div>

        <!-- 每日奖励 -->
        <div class="right stys" v-if="activated==2">
          <div class="title">
            <ul class="ul">
              <li>用户名</li>
              <li>充值金额</li>
              <li>比例</li>
              <li>收益</li>
              <li>充值时间</li>
            </ul>
          </div>
          <div class="cont">
            <ul v-for="item in tableData2" :key="item.id">
              <li class="name">
                <div class="user">{{ item.name }}</div>
              </li>
              <li>{{ item.recharge }}</li>
              <li>{{ item.ratio }}</li>
              <li>{{ item.money }}</li>
              <li>
                <span class="time">{{ item.endTime }}</span>
                <span class="time">{{ item.firstTime }}</span>
              </li>
            </ul>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              layout="prev, pager, next"
              :total="tableData2.total"
              background
              small
              :current-page.sync="currentPage"
              @current-change="getPageNum2"
              :page-size='5'
            >
            </el-pagination>
          </div>
        </div>
        <!-- 消耗奖励 -->
        <div class="right stys" v-if="activated==3">
          <div class="title">
            <ul class="ul">
              <li>用户名</li>
              <li>消耗金额</li>
              <li>比例</li>
              <li>收益</li>
              <li>消耗时间</li>
            </ul>
          </div>
          <div class="cont">
            <ul v-for="item in tableData3" :key="item.id">
              <li class="name">
                <div class="user">{{ item.name }}</div>
              </li>
              <li>{{ item.recharge }}</li>
              <li>{{ item.ratio }}</li>
              <li>{{ item.money }}</li>
              <li>
                <span class="time">{{ item.endTime }}</span>
                <span class="time">{{ item.firstTime }}</span>
              </li>
            </ul>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              layout="prev, pager, next"
              :total="tableData2.total"
              background
              small
              :current-page.sync="currentPage"
              @current-change="getPageNum3"
              :page-size='5'
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <div class="left">
          <img src="../../../assets/img/tui-1.png" alt="" />
        </div>
        <div class="center">
          <img src="../../../assets/img/tui-2.png" alt="" />
        </div>
        <div class="right">
          <img src="../../../assets/img/tui-3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navheader from "@/components/navheader.vue";
export default {
  components: {
    navheader,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],

  data() {
    return {
      // 邀请码
      inviteText: "8FPXPW",
      // 推广链接
      gooutText: "http://www.....uhiuhgiugiuhoiguy",
      // 切换按钮状态
      activated: 1,
      valueUrl: "http://www.gdskins.com",
      tuiguangren: "",
      valueCode: "TRtqaetA",
      tableData1: [],
      tableData2: [],
      tableData3: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      dateList: [
        {
          name: "推广统计",
          state: false,
          val: 0,
        },
      ],
      payInfoData: {
        flag: true,
        type: "",
        checked2: false,
        checked1: false,
      }, // 充值提示
    };
  },
  mounted() {
    this.getList();
    this.getList1(0);
    this.getPageNum1(1)
  },
  methods: {
     // 点击邀请记录页码获取数据
    getPageNum1(index) {
      let param = {
        page: index,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/Invite/offlinelist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          this.allmoney = res.data.data.allmoney;
          this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].create_time.substring(0, 10);
            let endTime = timeData[i].create_time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData1 = timeData;
        });
    },
    // 点击每日奖励页码获取数据
    getPageNum2(index) {
      let param = {
        page: index,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/Invite/offline", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          // this.allmoney = res.data.data.allmoney;
          // this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].time.substring(0, 10);
            let endTime = timeData[i].time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData2 = timeData;
        });
    },
    // 点击消耗奖励页码获取数据
    getPageNum3(index) {
      let param = {
        page: index,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Invite/waterlist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          // this.allmoney = res.data.data.allmoney;
          // this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].time.substring(0, 10);
            let endTime = timeData[i].time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData3 = timeData;
        });
    },
    // 控制邀请记录和每日奖励切换的函数
    change(index) {
      // console.log(index);
      this.currentPage = 1
      if (index == 1) {
        this.activated = 1;
        this.getPageNum1(1)
      } else if(index == 2){
        this.activated = 2;
        this.getPageNum2(1)
      }else if(index == 3) {
        this.activated = 3
        this.getPageNum3(1)
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1; // 切换每页条数时，重置为第一页
      this.getList1(0);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList1(0);
    },
    //挑战推广详情
    goLonger() {
      this.$router.push({
        path: `/SpreadLonger`,
      });
    },
    tuiguang() {
      let param = {
        invite_code: this.tuiguang,
      };
      this.$axios
        .post("index/User/bindInviter", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "绑定失败，" + data.msg,
              type: "info",
            });
          }
        });
    },
    //我的推广链接和邀请码
    getList() {
      let param = {};
      this.$axios
        .post("index/Invite/inviteInfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.valueUrl = data.data.invite_url;
            this.valueCode = data.data.invite_code;
          }
        });
      this.$axios
        .post("index/User/playerInfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.tuiguangren = data.myInviter;
            if (!this.tuiguangren) {
              this.tuiguangren = "官方";
            }
          }
        });
    },
    //我的下线7天/30天/今天/全部
    getList1(days) {
      let param = {
        days: days,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/Invite/offline", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].time.substring(0, 10);
            let endTime = timeData[i].time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData2 = timeData;
          if (data.status == 1) {
            //console.log(data.data.people_num)
            if (data.data.people_num) {
              this.value1 = data.data.people_num;
            }
            if (data.data.ratio) {
              this.value2 = data.data.ratio;
            }
            if (data.data.invite_commission) {
              this.value3 = data.data.invite_commission;
            }
            if (data.data.water_ratio) {
              this.value4 = data.data.water_ratio;
            }

            for (let i = 0; i < this.tableData1.length; i++) {
              if (this.tableData1[i].status == 1) {
                this.tableData1[i].state = "已结算";
              } else {
                this.tableData1[i].state = "未结算";
              }
            }
          }
        });
      this.$axios
        .post("index/Invite/offlinelist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          this.allmoney = res.data.data.allmoney;
          this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].create_time.substring(0, 10);
            let endTime = timeData[i].create_time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData1 = timeData;
        });
    },

    //复制
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },

    //我的下线 详情
    getLonger() {
      let param = {};
      this.$axios
        .post("index/Invite/offlineList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          console.log(data);
          if (data.status == 1) {
            this.tableData1 = data.data.list;
          }
        });
    },
    getState(name, val) {
      for (let i = 0; i < this.dateList.length; i++) {
        if (name == this.dateList[i].name) {
          this.dateList[i].state = true;
        } else {
          this.dateList[i].state = false;
        }
      }
      this.getList1(val);
    },
    checkPayInfo() {
      if (this.payInfoData.checked1 && this.payInfoData.checked2) {
        // if (this.payInfoData.type) {
        this.payInfoData.flag = false;
        // }
      } else {
        this.$message.warning("请阅读并勾选所有协议");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100px;
  height: 40px;
  color: aqua;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid aqua;
}
.spread {
  // overflow: hidden;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // background: #0f242f88;
  background: rgba(71, 201, 243, 0.14);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 30px 20px 30px;
  margin-top: 20px;
  .top {
    width: 100%;
    height: 130px;
    padding-bottom: 40px;
    box-sizing: border-box;
    // background-color: red;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    .top-left {
      // margin-left: 40px;
      color: white;
      .inviteInput {
        margin-top: 10px;
        position: relative;
        border-radius: 5px;
        input {
          background-color: #11142b;
          color: white;
          padding: 10px 15px;
          font-size: 14px;
          border: none;
          border-radius: 5px;
          outline: none;
        }
        .copy {
          position: absolute;
          top: 0;
          right: 5px;
          z-index: 100;
          color: aqua;
          font-size: 14px;
          margin-top: 8px;
          background-color: #11142b;
          padding-left: 8px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 14px;
            height: auto;
            margin-top: 2px;
            display: block;
          }
        }
      }
    }
    .top-center {
      margin-left: 40px;
      color: white;
      .gooutInput {
        margin-top: 10px;
        position: relative;
        background-color: #11142b;
        border-radius: 5px;
        overflow: hidden;
        input {
          background-color: #11142b;
          color: white;
          padding: 10px 15px;
          font-size: 14px;
          border: none;
          outline: none;
        }
        .copy {
          position: absolute;
          top: 0;
          right: 5px;
          z-index: 100;
          color: aqua;
          font-size: 14px;
          margin-top: 8px;
          background-color: #11142b;
          padding-left: 8px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 14px;
            height: auto;
          }
        }
      }
    }
    .top-right {
      margin-left: 40px;
      margin-top: -20px;
      font-size: 18px;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      color: white;
      img {
        display: inline-block;
        width: 25px;
        height: auto;
        margin-right: 2px;
      }
      .num {
        width: 200px;
        display: flex;
        align-items: center;
      }
      .awart {
        // margin-left: 10px;
        width: 200px;
        display: flex;
        align-items: center;
      }
      .proportion {
        // margin-left: 20px;
        margin-top: 5px;
        width: 200px;
        display: flex;
        align-items: center;
      }
    }
  }
  .content {
    width: 600px;
    height: 442px;
    margin-top: -10px;
    padding: 10px 20px;
    background-color: #151627;
    border: 1px solid #b000d6;
    border-radius: 3px;
    // background-color: yellow;
    .change {
      width: 100%;
      height: 50px;
      text-align: center;
      padding-top: 12px;
      box-sizing: border-box;
      color: white;
      .active {
        background-color: #3699ff;
      }
      span {
        border: 1px solid aqua;
        padding: 5px 8px;
        // color: white;
      }
      #right {
        margin-left: 8px;
      }
    }
    .stys {
      background-color: #151627;
      width: 100%;
      height: 401px;
      .title {
        .ul {
          width: 100%;
          height: 50px;
          background-image: url(../../../assets/img/mes-invite-bg.png);
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            flex: 1;
            text-align: center;
            color: white;
          }
        }
      }
      .cont {
        height: 263px;
        ul {
          width: 100%;
          // background-color: #151627 !important;
          height: auto !important;
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            flex: 1;
            color: white;
            height: 50px;
            border-top: 2px solid #1f1847;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .time {
              font-size: 12px;
              display: block;
            }
          }
        }
      }
      .pagination {
        width: 100%;
        height: 40px;
        margin-top: 10px;
        text-align: center;

        /deep/ .el-pagination.is-background .el-pager li {
          border-radius: 6px;
        }
        /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #30215f;
        }
        /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #30215f;
        }
        /deep/ .el-pagination .btn-next,
        .el-pagination .btn-prev .el-icon {
          background-color: #333542;
        }
      }
      .total {
        // background-color: red;
        margin-top: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .s1 {
          color: white;
          span {
            color: #eeb303;
          }
        }
        .s2 {
          margin-left: 100px;
          color: white;
          span {
            color: #6209a3;
          }
        }
      }
    }
    // 右侧样式
    .right {
      background-color: #151627;
      width: 100%;
      height: 401px;
    }
  }
  // 底部样式
  .footer {
    width: 900px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    div {
      width: 260px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

/deep/ .el-pagination.is-background .el-pager li {
  border-radius: 6px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #30215f;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #30215f;
}
/deep/ .el-pagination .btn-next .btn-prev,
.el-pagination .btn-prev .el-icon {
  background-color: #333542;
}
/deep/ .el-pager li.active,
.el-pager {
  color: white !important;
}
</style>