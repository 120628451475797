<template>
	<div class="box">
		<div class="ment-one">
			<div></div>
		</div>
		<div class="ment-two">
			<img style="height: 50px;width: auto;" src="../assets/img/CDK22.png" />
			<!-- <span>CDK兑换</span> -->
			<span>CDKEY领取</span>
		</div>
		<div class="ment-five"></div>
		<div class="ment-four">
			<span style="margin-right: 12px;">兑换码</span>
			<input style="width: 200px; height: 40px;padding-left: 12px;" placeholder="请输入兑换码" type="text" v-model="cdk" />
		</div>

		<div class="ment-btn">
			<el-button class="el-btn" @click="getcdk" :disabled="loading"
				:style="{ 'background-color': loading ? '#949493' : '#e9b10e' }"><i v-if="loading"
					class="el-icon-loading"></i>立即兑换</el-button>
		</div>
		<div class="win-box" v-if="winState">
			<mywin :winList="winList" :winState="winState" :isis="true" @winexchange="winexchange($event)"
				@winget="winget($event)" @winX="winX($event)"></mywin>
		</div>
		<div class="win-box" v-if="money">
			<div class="win">
				<div class="win-conm">
					<div class="win-tips">恭喜获得</div>
				</div>
				<div class="win-conm" style="color: white;display: flex;align-items: center;flex-flow: row;">
					<div>{{money}}</div><img src="../assets/img/money.png" style="width: 24px;">
				</div>
				<div class="win-span2" @click="winState = false;money = null">放入背包</div>
			</div>
		</div>
		<navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import navheader from "@/components/navheader.vue";
	import CdkChange from "@/views/cdk-change/cdk-change.vue";
	import mywin from "@/components/my_win.vue";
	export default {
		components: {
			navheader,
			CdkChange,
			mywin
		},
		props: ["yidongmenu", "showNav", "openHongbao"],
		data() {
			return {
				winState: false,
				winList: [],
				loading: false,
				payTitle: "",
				payTitleIcon: "wechat.svg",
				payFooter: "",
				payFooterDesc: "",
				payBox: false,
				cdk: "",
				payType: "wechat",
				list: [],
				payObj: {
					rmb: 0,
					money: 5,
					state: false
				},
				money: null,
				rmb: 0,
				ratioList: [],
				firstGiveValidate: false,
				ordinaryValidate: false,
				firstGive: {}, //新用户活动
				ordinaryActivity: {}, //非新用户充值活动
				is_new: false, //是否是新用户
				userInfo: {},
				payInfoData: {
					flag: false,
					type: "",
					checked2: false,
					checked1: false,
				}, // 充值提示
				// cdk兑换的皮肤
				cdk_skin: {},
			};
		},
		mounted() {
			this.payBox = false;
			this.amountList();
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			this.userInfo = userInfo;
			console.log(this.userInfo);
			if (this.$route.query.type) {
				this.payType = this.$route.query.type;
			}
		},
		methods: {
			//子组件修改父组件
			winexchange(msg) {
				this.winState = msg;
			},
			winget(msg) {
				this.winState = msg;
			},
			winX(msg) {
				this.winState = msg;
			},
			//充值送多少
			// getRatio() {
			//   this.$axios.get("index/User/giveAboutRecharge").then((res) => {
			//     let data = res.data;
			//     console.log(data);
			//     if (data.status == 1) {
			//       this.ratioList = data.data;
			//     }
			//   });
			// },
			// 跳转到个人账单
			goToBill() {
				this.$router.push({
					path: `/Bill`,
				});
			},
			// 联系客服
			concact() {
				// http://wpa.qq.com/msgrd?v=3&uin=2484784479&site=qq&menu=yes
				// tencent://message/?uin=1187276773&Site=&Menu=yes
				// window.open("http://wpa.qq.com/msgrd?v=3&uin=1064097081&site=qq&menu=yes");
				window.open(
					"https://qm.qq.com/cgi-bin/qm/qr?k=TFB6vBuBWEJpO1H8VADjLQZP9PpF8OUM&jump_from=webapi&authKey=tUy1AIU9X4U0Ie5WNGGDvFFi7SSs7is4vZ0qeHg8iu6KUWm5nMHMYVwly29zHVFB"
				);
			},
			//input框
			getInput(value) {
				//  /^(0\.0[1-9]|0\.[1-9]\d|[1-9]\d?(\.\d\d)?|[1-4]\d\d(\.\d\d)?|500)$/
				if (value == "") {
					this.list[6].money = "";
					this.list[6].rmb = 0;
				} else {
					this.list[6].money = value;
					this.list[6].rmb = (value * this.rmb).toFixed(2);
				}
				value = Number(value);

				let money = Number(this.ratioList.money);
				let limit = Number(this.ratioList.limit);
				if (money > 0 && limit > 0) {
					if (value >= money && value <= limit) {
						this.setTrue();
					} else {
						this.setFalse();
					}
				} else if (money == 0 && limit == 0) {
					this.setTrue();
				} else if (money > 0 && limit == 0) {
					console.log(value >= money);
					if (value >= money) {
						this.setTrue();
					} else {
						this.setFalse();
					}
				} else if (money == 0 && limit > 0) {
					if (value <= limit) {
						this.setTrue();
					} else {
						this.setFalse();
					}
				} else {
					this.setFalse();
				}
			},
			CDKInput(e) {
				this.CDK = e.detail.value;
			},
			setTrue() {
				this.list[this.list.length - 1].is_first_give = true;
				this.list[this.list.length - 1].billieState = true;
				this.list[this.list.length - 1].billie = this.ratioList.billie;
			},
			setFalse() {
				this.list[this.list.length - 1].is_first_give = false;
				this.list[this.list.length - 1].billieState = false;
				this.list[this.list.length - 1].billie = 0;
			},
			//加
			addInput() {
				if (this.list[6].money < 5) {
					this.list[6].money = 5.0;
					this.list[6].rmb = 5.0 * this.rmb;
				} else {
					this.list[6].money = (Number(this.list[6].money) + 1).toFixed(2);
					this.list[6].rmb = (this.list[6].money * this.rmb).toFixed(2);
				}
				// console.log(11)
				for (let i = 0; i < this.ratioList.length; i++) {
					if (this.list[6].money >= Number(this.ratioList[i].money)) {
						this.list[6].billie = this.ratioList[i].billie;
						this.list[6].billieState = true;
					} else {
						this.list[6].billieState = false;
					}
				}
			},
			//减
			remInput() {
				if (this.list[6].money <= 5) {
					return;
				}
				this.list[6].money = ((this.list[6].money * 100 - 1 * 100) / 100).toFixed(
					2
				);
				this.list[6].rmb = (this.list[6].money * this.rmb).toFixed(2);
				for (let i = 0; i < this.ratioList.length; i++) {
					if (this.list[6].money >= Number(this.ratioList[i].money)) {
						this.list[6].billie = this.ratioList[i].billie;
						this.list[6].billieState = true;
					} else {
						this.list[6].billieState = false;
					}
				}
			},
			amountList() {
				let param = {
					player_id: localStorage.getItem("id"),
				};
				this.$axios
					.post("index/User/giveAboutRecharge", this.$qs.stringify(param))
					.then((res) => {
						let data = res.data;
						this.is_new = data.data.new;
						if (data.status == 1) {
							// this.ratioList = data.data.giveInfo;
							// if(data.data.firstGive){
							//   this.firstGiveValidate = true;
							//   this.firstGive = data.data.firstGive;
							// }
							if (this.is_new) {
								if (data.data.recharge_activity.length > 0) {
									data.data.recharge_activity.forEach((e) => {
										if (e.type == 1) {
											this.firstGiveValidate = true;
											this.ratioList = e;
											this.firstGive = e;
										}
										if (e.type == 2) {
											this.firstGiveValidate = false;
											this.ratioList = e;
											this.ordinaryActivity = e;
										}
									});
								}
							} else {
								this.firstGiveValidate = false;
								if (data.data.recharge_activity.length > 0) {
									data.data.recharge_activity.forEach((e) => {
										if (e.type == 2) {
											this.ratioList = e;
											this.ordinaryActivity = e;
										}
									});
								}
							}
						}
						// 获取充值列表
						this.$axios.get("/index/pay/chargeInfoList").then((res) => {
							let data = res.data;
							if (data.status == 1) {
								this.rmb = data.data.exchange_rate;
								this.payObj.rmb = data.data.exchange_rate * 5;
								let list = res.data.data.list;
								list.push(this.payObj);
								list.forEach(function(item, index) {
									item.state = false;
									if (index == 0) {
										item.state = true;
									}
								});
								this.list = list;
								// console.log(this.list);
								for (let i = 0; i < this.list.length; i++) {
									let money = Number(this.list[i].money); //当前充值列表金额
									let lower_limit = Number(this.ratioList.money); //后台设定下限
									let upper_limit = Number(this.ratioList.limit); //后台设定上限
									// console.log(money,lower_limit,upper_limit);
									if (lower_limit > 0 && upper_limit > 0) {
										if (money >= lower_limit && money <= upper_limit) {
											this.list[i].billie = this.ratioList.billie;
											this.list[i].billieState = true;
										}
									}
									if (lower_limit == 0 && upper_limit == 0) {
										this.list[i].billie = this.ratioList.billie;
										this.list[i].billieState = true;
									}
									if (lower_limit > 0 && upper_limit == 0) {
										if (money >= lower_limit) {
											this.list[i].billie = this.ratioList.billie;
											this.list[i].billieState = true;
										}
									}
									if (lower_limit == 0 && upper_limit > 0) {
										if (money <= upper_limit) {
											this.list[i].billie = this.ratioList.billie;
											this.list[i].billieState = true;
										}
									}
								}
							}
						});
						// console.log(this.ratioList);
						// console.log(this.ordinaryActivity);
						if (!this.empty(this.ordinaryActivity)) {
							this.ordinaryValidate = true;
						}
					});
			},

			//领取红包
			getcdk() {
				if (!this.cdk) {
					this.$message({
						message: "请输入cdk口令",
						type: "warning",
					});
					return;
				}
				// this.hongbaoState1 = false;
				// this.hongbaoState2 = true;
				let param = {
					password: this.cdk,
				};
				this.$axios
					.post("index/Activity/cdk", this.$qs.stringify(param))
					.then((res) => {
						let data = res.data;
						if (data.status == 1) {
							this.$message({
								message: "CDK成功兑换",
								type: "success",
							});
						} else {
							this.$message({
								message: data.msg,
								type: "warning",
							});
						}
						// 皮肤
						if (data.data && data.data[0].id) {
							this.cdk_skin = data.data[0];
							this.winList.skins_info = [];
							this.winList.skins_info[0] = data.data[0];
							this.winList.skins_info[0]['background'] = require("../assets/img/openBox/4.png");
							this.openBoxState = false;
							this.winState = true;
						} else if (data.data) {
							this.money = data.data;
							this.$parent.getPlayerInfo();
						}
					});
			},

			empty(obj) {
				for (let key in obj) {
					return false; //非空
				}
				return true; //为空
			},
			epayjump() {
				this.$router.push = "https://69csgoskins.com.cn/SDK/index.php";
			},
			payInfo(t) {
				let money = 0;
				for (let i = 0; i < this.list.length; i++) {
					if (this.list[i].state) {
						money = this.list[i].money;
					}
				}
				console.log(Number(money));
				if (this.userInfo.total_amount == "0.00" || money > 199) {
					this.payInfoData.flag = true;
					this.payInfoData.type = t;
				} else {
					this.payAction(t);
				}
			},
			checkPayInfo() {
				if (this.payInfoData.checked1 && this.payInfoData.checked2) {
					if (this.payInfoData.type) {
						this.payInfoData.flag = false;
						this.payAction(this.payInfoData.type);
					}
				} else {
					this.$message.info("请阅读并勾选所有协议");
				}
			},
			payAction(t) {
				if (this.list[6].money < 5 && this.list[6].state == true) {
					this.$message({
						message: "充值Z币不少于5个",
						type: "warning",
					});
					return;
				}

				if (t == "wechat") {
					this.loading = true;
					this.payTitle = "微信支付";
					this.payTitleIcon = "wechat.svg";
					this.payFooter = "请使用微信扫一扫";
					this.payFooterDesc = "扫描二维码完成支付";
					this.pay("weixin");
					return;
				}
				this.loading = true;
				this.payTitle = "支付宝支付";
				this.payTitleIcon = "alipay.svg";
				this.payFooter = "请使用支付宝扫一扫";
				this.payFooterDesc = "扫描二维码完成支付";
				this.pay("zhifubao");
			},
			pay(type) {
				let money = 0;
				for (let i = 0; i < this.list.length; i++) {
					if (this.list[i].state) {
						money = this.list[i].money;
					}
				}

				let _this = this;
				let data = {
					mode: type,
					money: money,
					mobile: this.userInfo.mobile, 
					isMobile :this.isMobile()
				};
				_this.$axios.post("/index/Pay/recharge", data).then((res) => {
					let data = res.data;
					this.loading = false;
					if (data.status == 1) {
						//this.$refs.qrcode.innerHTML = "";
						this.payBox = true;
						this.loading = false;
						this.$nextTick(() => {
							const qrCode = new QRCode("qrcode", {
								width: 160,
								height: 160,
								text: res.data.data,
							});
						});
					} else {
						this.$message({
							message: res.data.msg,
							// message: "微信支付维护中，请使用支付宝充值",
							type: "warning",
						});
					}
				});
			},
			handleClose() {
				this.payBox = false;
				this.$refs.qrcode.innerHTML = "";
			},
			saveImg() {
				var canvasData = this.$refs.qrcode.getElementsByTagName("canvas");
				var a = document.createElement("a");
				var event = new MouseEvent("click");
				a.href = canvasData[0].toDataURL("image/png");
				a.download = "支付二维码";
				a.dispatchEvent(event);
			},
			weixinMoney(index) {
				for (let i = 0; i < this.list.length; i++) {
					this.list[i].state = false;
				}
				this.list[index].state = true;
			},
			zhifuMoney(index) {
				for (let i = 0; i < this.list.length; i++) {
					this.list[i].state = false;
				}
				this.list[index].state = true;
			},
			epayMoney(index) {
				for (let i = 0; i < this.list.length; i++) {
					this.list[i].state = false;
				}
				this.list[index].state = true;
			},
		},
	};
</script>
<style lang="less" scoped>
	.box {
		padding-top: 100px;
	}

	.ment-one {
		display: flex;
		justify-content: space-between;

		span {
			color: #17b4ed;
		}

		span:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.ment-two {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			font-size: 32px;
			color: #c3c3e2;
			margin-left: 15px;
		}

		img {
			// width: 50px;
			height: auto;
		}
	}

	.ment-three {
		margin-top: 20px;
		text-align: center;
		font-size: 20px;
		color: #e9b10e;
	}

	.ment-four {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			font-size: 20px;
			color: #c3c3e2;
			margin-left: 15px;
		}
	}

	.ment-five {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			font-size: 20px;
			color: #c3c3e2;
			margin-left: 15px;
		}
	}

	.ment-list {
		margin-top: 30px;
		display: flex;
		justify-content: center;

		ul {
			// display: flex;
			//flex-wrap: wrap;

			li {
				float: left;
				width: 145px;
				margin: 5px;
				background-color: #333542;
				height: min-content;
				border-radius: 5px;
				position: relative;

				.yidong {
					margin: 25px auto 0 !important;
					height: 50px !important;

					img {
						width: 50% !important;
						max-height: 200% !important;
						position: initial !important;
					}

				}

				.list-top {
					text-align: center;
					margin: 35px auto 10px;
					width: 117px;
					height: 117px;
					position: relative;

					img {
						width: 100%;
						max-height: 100%;
						position: absolute;
						bottom: 0;
						left: 0;
					}
				}

				.yidonglist-warp {
					padding-bottom: 0 !important;
				}

				.list-warp {
					display: flex;
					flex-direction: column;
					justify-content: center;
					// padding-left: 40PX;
					// padding-right: 40px;
					padding-bottom: 20px;

					.list-top1 {
						font-size: 14px;
						color: #c3c3e2;
						margin: 60px auto 0;
						height: 68px;
					}

					.list-input {
						padding-left: 10px;
						padding-right: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 20px;
						position: relative;
						z-index: 66;

						.input /deep/ .el-input__inner {
							max-width: 50px;
							padding: 0;
							color: #e9b10e;
							background-color: #333542;
							height: 30px;
							line-height: 30px;
						}

						span {
							font-size: 30px;
							color: #848492;
						}

						.list-span1 {
							margin-right: 20px;
						}

						.list-span2 {
							margin-left: 10px;
						}
					}

					.list-con {
						text-align: center;
						// margin-top: 30px;
						color: #e9b10e;
						font-size: 20px;
					}

					.list-bot {
						text-align: center;
						margin-top: 10px;
						font-size: 14px;
						color: #848492;
						white-space: nowrap;
					}
				}

				.ment-state {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 5px;
					border: 1px solid #e9b10e;
					background-color: rgba(233, 177, 14, 0.1);
				}

				.give {
					position: absolute;
					top: 1px;
					left: 0;
					width: 100%;
					height: 32px;
					line-height: 32px;
					margin-left: 1px;
					background-color: #e9b10e;
					color: #1a1c24;
					font-size: 13px;
					text-align: center;
					border-top-right-radius: 5px;
					border-top-left-radius: 5px;
				}
			}

			.list-li {
				// padding: 40px 20px 20px 20px;
			}

			li:hover {
				cursor: pointer;
			}
		}
	}

	.ment-btn {
		margin-top: 48px;
		display: flex;
		justify-content: center;

		.el-btn {
			padding: 14px 26px;
			font-size: 16px;
			color: #1a1c24;
			background-color: #e9b10e;
			border-radius: 5px;
			font-weight: 600;
			border: none;
		}

		/*span:hover {
	  cursor: pointer;
	}*/
	}

	.win-conm {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.win-tips {
			height: 60px;
			color: #fff;
			font-size: 16px;
			line-height: 60px;
			font-weight: bold;
		}

		ul {
			margin: -5px;
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			li {
				.win-warp {
					border-radius: 5px;
					overflow: hidden;
					// width: 100%;
					cursor: pointer;
					position: relative;

					.win-text {
						text-overflow: ellipsis;
						position: absolute;
						width: 100%;
						text-align: center;
						left: 0;
						right: 0;
						bottom: 0;
						margin-left: auto;
						margin-right: auto;
						bottom: 30px;
						font-size: 8px !important;
						color: white;
					}

					.win-textt {
						width: 100%;
						padding: 6px 0;
						background-color: #e9b10e;
						background-size: 100% 100%;
						border: 1px solid #ffea95;
						color: #000000;
						// padding: 10px;
						//overflow: hidden;
						//white-space: nowrap;
						text-overflow: ellipsis;
						font-size: 10px;
					}
				}
			}
		}
	}

	.win_container {
		display: grid !important;
		gap: 5px 10px;
		overflow: auto;
		height: 320px;
		overflow-x: hidden;
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;

		li {
			width: 100%;
		}

		.win-warp {
			display: flex;
			width: 100%;
			flex-direction: column;
		}
	}

	.win {

		width: 450px;
		padding: 20px;
		background-color: #1c2230;
		position: relative;
		box-sizing: border-box;
		border-radius: 10px;

		.win-span2 {
			padding: 10px 0;
			height: 20px;
			background-color: #e9b10e;
			background-size: 100% 100%;
			border: 1px solid #ffea95;
			color: #000000;
			display: flex;
			font-weight: bold;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			margin-top: 15px;
		}

		.win-span2:hover {
			cursor: pointer;
		}
	}

	//中奖后盒子
	.win-box {
		position: fixed;
		z-index: 99999;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.7);
		display: grid;
		place-items: center;
	}

	.payinfoBox {
		/deep/ .el-dialog__title {
			color: #808085;
		}

		/deep/ h3 {
			color: #eaeaea;
			font-weight: normal;
			margin-bottom: 16px;
		}

		/deep/ .el-checkbox {
			color: #17b4ed;
		}

		/deep/ .btn-area {
			text-align: center;
			padding: 16px 0 8px 0;
		}
	}

	.pay {
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		min-height: 100vh;
		background-color: #1a1c24;
		padding-bottom: 24px;

		.pay-warp {
			padding: 16px;
		}

		.pay-title {
			font-size: 20px;
			color: #c3c3e2;
		}

		.pay-hint {
			margin-top: 10px;
			text-align: right;

			// display: flex;
			// justify-content: space-between;
			// align-items: center;
			.payBill {
				font-size: 20px;
				color: #848492;
				line-height: 1;
				cursor: pointer;
				margin-bottom: 12px;

				:hover {
					color: #e9b10e;
				}
			}

			.tMsg {
				color: #eaeaea;
				font-size: 16px;

				span {
					color: #17b4ed;
					cursor: pointer;
				}
			}

			.pay-hint-left {
				display: flex;
				align-items: center;

				span {
					font-size: 16px;
					color: #848492;
				}

				img {
					width: auto;
					height: 16px;
				}
			}

			.pay-hint-right {
				font-size: 16px;
				color: #848492;
			}

			.pay-hint-right:hover {
				cursor: pointer;
				color: #e9b10e;
			}
		}

		.pay-ment {
			margin-top: 30px;

			.ment-one {
				display: flex;
				justify-content: space-between;

				span {
					color: #17b4ed;
				}

				span:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}

			.ment-two {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					font-size: 32px;
					color: #c3c3e2;
					margin-left: 15px;
				}

				img {
					// width: 50px;
					height: auto;
				}
			}

			.ment-three {
				margin-top: 20px;
				text-align: center;
				font-size: 20px;
				color: #e9b10e;
			}

			.ment-four {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					font-size: 20px;
					color: #c3c3e2;
					margin-left: 15px;
				}
			}

			.ment-five {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					font-size: 20px;
					color: #c3c3e2;
					margin-left: 15px;
				}
			}

			.ment-list {
				margin-top: 30px;
				display: flex;
				justify-content: center;

				ul {
					// display: flex;
					//flex-wrap: wrap;

					li {
						float: left;
						width: 145px;
						margin: 5px;
						background-color: #333542;
						height: min-content;
						border-radius: 5px;
						position: relative;

						.yidong {
							margin: 25px auto 0 !important;
							height: 50px !important;

							img {
								width: 50% !important;
								max-height: 200% !important;
								position: initial !important;
							}

						}

						.list-top {
							text-align: center;
							margin: 35px auto 10px;
							width: 117px;
							height: 117px;
							position: relative;

							img {
								width: 100%;
								max-height: 100%;
								position: absolute;
								bottom: 0;
								left: 0;
							}
						}

						.yidonglist-warp {
							padding-bottom: 0 !important;
						}

						.list-warp {
							display: flex;
							flex-direction: column;
							justify-content: center;
							// padding-left: 40PX;
							// padding-right: 40px;
							padding-bottom: 20px;

							.list-top1 {
								font-size: 14px;
								color: #c3c3e2;
								margin: 60px auto 0;
								height: 68px;
							}

							.list-input {
								padding-left: 10px;
								padding-right: 10px;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-top: 20px;
								position: relative;
								z-index: 66;

								.input /deep/ .el-input__inner {
									max-width: 50px;
									padding: 0;
									color: #e9b10e;
									background-color: #333542;
									height: 30px;
									line-height: 30px;
								}

								span {
									font-size: 30px;
									color: #848492;
								}

								.list-span1 {
									margin-right: 20px;
								}

								.list-span2 {
									margin-left: 10px;
								}
							}

							.list-con {
								text-align: center;
								// margin-top: 30px;
								color: #e9b10e;
								font-size: 20px;
							}

							.list-bot {
								text-align: center;
								margin-top: 10px;
								font-size: 14px;
								color: #848492;
								white-space: nowrap;
							}
						}

						.ment-state {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							border-radius: 5px;
							border: 1px solid #e9b10e;
							background-color: rgba(233, 177, 14, 0.1);
						}

						.give {
							position: absolute;
							top: 1px;
							left: 0;
							width: 100%;
							height: 32px;
							line-height: 32px;
							margin-left: 1px;
							background-color: #e9b10e;
							color: #1a1c24;
							font-size: 13px;
							text-align: center;
							border-top-right-radius: 5px;
							border-top-left-radius: 5px;
						}
					}

					.list-li {
						// padding: 40px 20px 20px 20px;
					}

					li:hover {
						cursor: pointer;
					}
				}
			}

			.ment-btn {
				margin-top: 48px;
				display: flex;
				justify-content: center;

				.el-btn {
					padding: 14px 26px;
					font-size: 16px;
					color: #1a1c24;
					background-color: #e9b10e;
					border-radius: 5px;
					font-weight: 600;
					border: none;
				}

				/*span:hover {
        cursor: pointer;
      }*/
			}
		}

		.pay-ment /deep/ .el-tabs__item {
			margin-bottom: 6px;
		}

		.pay-ment /deep/ .el-tabs__item.is-active {
			color: #c3c3e2;
		}

		.pay-ment /deep/ .el-tabs__item {
			color: #848492;
			font-size: 20px;
		}

		.el-button--warning {
			background-color: #e9b10e;
			color: #1a1c24;
			font-weight: 600;
		}
	}
</style>

<style lang="less">
	.el-dialog {
		display: flex;
		flex-direction: column;
		margin: 0 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 415px;
		min-width: 300px;
		background-color: #333542;
		color: #c3c3e2;
		font-size: 18px;
		line-height: 44px;
	}

	.el-dialog__header {
		background-color: #333542;
	}

	.dialog-title {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #333542;

		div {
			display: flex;
			align-items: center;

			img {
				width: 25px;
				margin: 0 16px 0 0;
			}
		}
	}

	.el-dialog__body {
		flex: 1;
		overflow: auto;
		background-color: #333542;
	}

	.dialog-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #2b2c37;

		img {
			max-width: 29px;
			max-height: 29px;
			margin-right: 16px;
		}

		div>p {
			font-size: 12px;
			line-height: 16px;
			display: block;
		}
	}

	.el-dialog__footer {
		background-color: #2b2c37;
	}

	.save-qrcode {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 32px;
	}

	.img-r0 {
		width: 40% !important;
	}

	.img-r1 {
		width: 50% !important;
	}

	.img-r2 {
		width: 60% !important;
	}

	.img-r3 {
		width: 70% !important;
	}

	.img-r4 {
		width: 80% !important;
	}

	.time {
		font-size: 14px;
	}

	.no-p {
		height: 23px !important;
	}

	.no-p-1 {
		height: 0 !important;
	}

	@media screen and (max-width: 767px) {
		.pay .pay-ment .ment-list ul li {
			width: 42% !important;
			// margin: 0 !important;
		}

		.win-box {
			left: 0 !important;
			margin-left: 0 !important;
		}

		.win-box {
			z-index: 99999999999;

			.win {
				width: 85vw !important;
				left: 0vw;
				top: 0vh;
				// transform: translateX(-50%);
				border-radius: 10px;

				.win-text {
					font-size: 12px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
</style>