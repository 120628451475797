<template>
	<div class="base-slider-pc" :class="{
      'small-screen': small_screen,
    }" :style="{
      right: getSliderRight,
    }" v-if="width > 600">
	<!-- gotTo('/task-center','/task-center') -->
		<div class="item" @click="showBox">
			<img src="@/assets/img/index/59b1698f-2699-4466-b8a2-1b7c58150087.webp" style="width: 30px; height: auto" />
			<span>签到</span>
		</div>
		<div class="item lucky-money_container" @click="openHongbao">
			<div class="lucky-money"></div>
			<span>红包</span>
		</div>
		<div class="item chat-group_container" @click="qqGroup()">
			<div class="chat-group"></div>
			<span>群聊</span>
		</div>
		<div class="item helper_container" @click="gotTo('/Payment','Mes','充值')">
			<div class="helper"></div>
			<span>充值</span>
		</div>
		<div class="item bb-group_container" @click="gotTo('/Dota','Mes','背包')">
			<div class="helper"></div>
			<span>背包</span>
		</div>
		<div class="item chat-group_container" @click="qqsGroup()">
			<img class="back-top" src="@/assets/img/index/kefu.png" style="width: 30px; height: auto" />
			<span>客服</span>
		</div>
		<div class="item chat-group_container" @click="goKefu()">
			<img class="back-top" src="@/assets/img/index/kefu.png" style="width: 30px; height: auto" />
			<span>在线客服</span>
		</div>
		<div class="item back-top_container" @click="backTop">
			<img class="back-top" src="@/assets/img/index/de1069ab-1fd6-46e3-b449-7b580c3d5f0a.webp" />
			<span>顶置</span>
		</div>
		<div class="switch" @click="changeSlider">
			<i :class="[status ? 'el-icon-arrow-left' : 'el-icon-arrow-right']"></i>
		</div>
	</div>
</template>

<script>
	export default {
		props:["showNav"],
		data() {
			return {
				// 小屏
				small_screen: false,
				// 状态 0展开 1折叠
				status: 1,
				width:''
			};
		},
		computed: {
			getSliderRight() {
				if (this.small_screen) {
					if (this.status) {
						return "-65px !important";
					} else {
						return "-10px !important";
					}
				} else {
					if (this.status) {
						return "-70px !important";
					} else {
						return "0px !important";
					}
				}
			},
		},
		created() {
			this.checkScreen();
			window.addEventListener("resize", () => {
				this.checkScreen();
			});
		},
		mounted() {
			this.width = document.body.clientWidth
		},
		methods: {
			showBox() {
				const width = document.body.clientWidth
				if(width > 550 ) {
					this.$emit("showBox");
					return
				}
				this.gotTo('/ActiveMove','/ActiveMove')
			},
			changeSlider() {
				this.status = this.status ? 0 : 1;
			},
			checkScreen() {
				if (window.innerWidth < 600) {
					this.small_screen = true;
					return;
				}
				this.small_screen = false;
			},
			backTop() {
				window.scrollTo({
					left: 0,
					top: 0,
					behavior: "smooth",
				});
			},
			gotTo(path,pathpc,pe) {
				if(this.showNav){
					this.$router.push({
						path: pathpc,
						query: {
							type: pe
						}
					});
				}else{
					this.$router.push({
						path,
					});
				}
			},
			qqGroup() {
				window.open(
					"http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=vDMYvVo9o_a9a1Plbt691nTbgAthqHTJ&authKey=rw7IQ5LHQzkc8a7m0JAv8KgLrplbjEbmbNXpBdRBMuNTHwAF%2Fv%2BTx9TGu9ZodU4S&noverify=0&group_code=955634995"
				);
			},
			goKefu() {
				this.$router.push('/kefu')
			},
			qqsGroup() {
				let u = navigator.userAgent;
				let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
				let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
				if (isAndroid) {
					window.open(
						"mqqwpa://im/chat?chat_type=wpa&uin=3692073479"
					);
					return
				}
				if (isIOS) {
					window.open("mqqapi://card/show_pslcard?src_type=internal&source=sharecard&version=1&uin=3692073479");
					return
				}
				window.open(
					"https://wpa.qq.com/msgrd?v=3&uin=3692073479&site=qq&menu=yes&jumpflag=1"
				);
			},
			openHongbao() {
				this.$emit("openHongbao");
			},
		},
	};
</script>

<style lang="less" scoped>
	div {
		-webkit-tab-highlight-color: transparent;
	}

	.small-screen {
		transform: scale(0.8) !important;
		right: -10px !important;
	}

	.base-slider-pc {
		position: fixed;
		right: 0;
		// bottom: calc(45vh - 114px);
		top: 0;
		bottom: 0;
		margin: auto;
		width: 70px;
		z-index: 10000;
		background-color: #0f1928;
		min-height: 200px;
		padding: 20px 0;
		transition: all 200ms ease-in-out;
		height: 520px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		z-index: 888888;
		transform: scale(1.1);
		box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
			0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);

		.switch {
			background-color: rgba(76, 91, 116, .8);
			height: 100px;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			position: absolute;
			left: -30px;
			top: 50%;
			transform: translateY(calc(-50% + 35px));
			text-align: center;

			i {
				color: #fff;
				font-size: 25px;
			}
		}

		.lucky-money_container {
			.lucky-money {
				width: 19.39px;
				height: 21.55px;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../../../assets/img/index/0985c3f1-e406-4fe1-8ec3-523325fdf746.webp");
			}

			&:hover {
				.lucky-money {
					background-image: url("../../../assets/img/index/d857d42b-9347-4b16-a309-359513068b6e.png");
				}
			}
		}

		.chat-group_container {
			.chat-group {
				width: 19.39px;
				height: 21.55px;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../../../assets/img/index/371e2603-1c1c-4d87-b183-64a519e61ee2.webp");
			}

			&:hover {
				.chat-group {
					background-image: url("../../../assets/img/index/8ba127e2-c21e-41a8-a684-e45e301f4dbe.png");
				}
			}
		}

		.back-top_container {
			margin-bottom: 0 !important;

			.back-top {
				width: 22px;
				height: 22px;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../../../assets/img/index/de1069ab-1fd6-46e3-b449-7b580c3d5f0a.webp");
			}

			&:hover {
				.back-top {
					background-image: url("../../../assets/img/index/3dfe3ebb-2753-471b-933b-a9486a730859.png") !important;
				}
			}
		}

		.bb-group_container {

			.helper {
				width: 30px;
				height: 30px;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../../../assets/img/index/beibao.png");
			}
			&:hover {
				.helper {
					background-image: url("../../../assets/img/index/beibao1.png") !important;
				}
			}
		}

		.helper_container {
			.helper {
				width: 24px;
				height: 24px;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../../../assets/img/index/cz.png");
			}

			&:hover {
				.helper {
					background-image: url("../../../assets/img/index/cz_s.png");
				}
			}
		}

		.item {
			color: #fff;
			font-size: 13px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			cursor: pointer;

			span {
				margin-top: 5px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

</style>