<template>
	<div class="container" v-if="show">
		<div class="flexleft">
			<ul>
				<li v-for="(item, index) in yidongmenu" :key="index" @click="goNavMenu(item.id, item.path, item.value)">
					<img :class="item.selState ? 'img1' : 'img2'" :src="item.selState ? item.img1 : item.img2"
						style="width: 24px" />
					<div :class="item.selState ? 'item-val-bian' : 'item-val'" style="font-size: 12px; margin-top: 3px">
						{{ item.value }}
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {

		data() {
			return{
				show:true
			}
		},
		mounted() {
			if(document.body.clientWidth<600) {
				this.show = true
			}else{
				this.show = false
			}
		},
		props: ["showNav", "yidongmenu", "openHongbao"],
		methods: {
			//左侧导航
			goNavMenu(id, path, name) {
				if(name == "我的"){
					// this.$parent.$parent.drawerFun();
					this.$router.push({
					path: `/${path}`,
				});
					return
				}
				if (name == "口令") {
					this.openHongbao();
					return;
				}
				for (let i = 0; i < this.yidongmenu.length; i++) {
					if (id == this.yidongmenu[i].id) {
						this.yidongmenu[i].selState = true;
					} else {
						this.yidongmenu[i].selState = false;
					}
				}
				this.$router.push({
					path: `/${path}`,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.container {
		height: 50px;
	}

	.flexleft {
		height: 50px;
		// position: fixed;
		z-index: 99999;
		width: 100%;
		display: flex;
		align-items: center;
		background-color: #10151b;
		color: #848492;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;

		ul {
			width: 100%;
			display: flex;
			flex-direction: row;
			white-space: nowrap;
			height: 100%;
			overflow: auto;

			li {
				display: flex;
				flex-direction: column;
				height: 100%;
				align-items: center;
				width: 100px;
				justify-content: center;
				color: #4c5b74;
			}
		}
	}

	.item-val-bian {
		color: rgba(254, 154, 10, 1);
	}
</style>