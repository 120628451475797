<template>
  <div class="container">
    <!-- <div class="spread-warp">
      <div class="roomleft-num">VIP福利</div>

      <div class="hist-list">
        <el-tabs type="border-card">
          <el-tab-pane label="VIP等级详情">
            <div class="bot">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="用户名称" width="180">
                </el-table-column>
                <el-table-column prop="grade" label="当前VIP等级" width="180">
                </el-table-column>
                <el-table-column
                  prop="upgrade"
                  label="升级条件:累积充值满"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="recharge"
                  label="当前充值流水"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  prop="gap"
                  label="升级下一级还需充值"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="ls" label="当前消费流水" width="180">
                </el-table-column>
                <el-table-column prop="status" label="是否达标" width="150">
                </el-table-column>
                <el-table-column prop="reward" label="奖励金币" width="100">
                </el-table-column>
                <el-table-column prop="give" label="奖励说明">
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="奖励列表">
            <div class="bot">
              <el-table :data="tableData1" style="width: 100%">
                <el-table-column prop="grade" label="VIP等级" width="130">
                </el-table-column>
                <el-table-column prop="upgrade" label="升级条件" width="130">
                </el-table-column>
                <el-table-column prop="reward" label="奖励金币">
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div> -->
    <!-- 标题 -->
    <div class="title">
      <img src="../assets/img/vip-move.png" alt="">
      <span>会员等级</span>
    </div>
    <div class="dscription">
      <span>尊敬的用户，您当前等级为</span>
      <span class="vipRank">{{grade}}</span>
    </div>
    <div class="money" style="margin-top:10px;color:white;font-size:16px;">
      <span>已充值</span><span>{{recharge}}</span> <span>升级至下一级还需充值</span><span>{{xu_recharge}}</span>
    </div>
    <div class="tableData">
      <div class="t1">
        <ul>
          <li>VIP等级</li>
          <li>升级条件</li>
          <li>奖励金币</li>
        </ul>
      </div>
      <div class="content" v-for="(item,index) in tableData1" :key="index">
        <ul :class="index%2==0?'bg1':'bg2'">
          <li>{{item.grade}}</li>
          <li>{{item.upgrade}}</li>
          <li>{{item.reward}}</li>
        </ul>
      </div>
    </div>
    
    <!-- 底部导航栏 -->
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>

<script>
import { parse } from "qs";
import navheader from "@/components/navheader.vue";
export default {
  components: { navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      grade: '',
      recharge: '',
      xu_recharge: '',
      totalSize: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      tableData1: [],
      totalSize: 0,
      pageSize: 10,
      totalSize1: 0,
      pageSize1: 10,
    };
  },
  mounted() {
    this.getskinToSteam(this.page);
    this.vip_reward(this.page);
  },
  methods: {
    getskinToSteam(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Lucky/vipfuli", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize = data.data.total;
            this.tableData = data.data.list;
            this.grade = data.data.grade
            this.recharge = data.data.recharge
            this.xu_recharge  = data.data.xu_recharge 
            console.log(this.tableData,'llllll')
          }
        });
    },
    //分页 所有记录
    currentChange(val) {
      this.getskinToSteam(val);
    },

    vip_reward(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Lucky/vipreward", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize1 = data.data.total;
            this.tableData1 = data.data.list;
          }
        });
    },

    goLucky() {
      this.$router.push({
        path: `/Ornament`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .container-warp {
    padding: 16px;
  }
  .roomleft-num {
    color: #c3c3e2;
    font-size: 20px;
  }

  //表格
  .hist-list /deep/ .el-table th,
  .hist-list /deep/ .el-table tr {
    background-color: #2b2c37;
  }
  .hist-list /deep/ .el-table td,
  .hist-list /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }
  .hist-list /deep/ .el-table::before {
    height: 0;
  }
  .hist-list /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #212e3e !important;
  }
  /*.list /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }*/
  .hist-list /deep/ .el-table__empty-block {
    background-color: #2b2c37;
  }

  //tabs
  //tabs
  .hist-list {
    margin-top: 20px;
  }
  .hist-list /deep/ .el-tabs--border-card {
    //overflow: hidden;
    background-color: #2b2c37;
    border: none;
    // border-radius: 5px;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: #1a1c24;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    background-color: #24252f;
    border: none;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #e9b10e;
  }
}
.payinfoBox {
  /deep/ .el-dialog__title {
    color: #808085;
  }
  /deep/ h3 {
    color: #eaeaea;
    //font-weight: normal;
    margin-bottom: 16px;
    font-size: 12px;
  }
  /deep/ .el-checkbox {
    color: #17b4ed;
  }
  /deep/ .btn-area {
    text-align: center;
    padding: 16px 0 8px 0;
  }
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/bg-move.jpg) !important;
  background-size: 100% 100%;
  background-position: 0px 70px;
  background-repeat: no-repeat;
  background-attachment: fixed;
 -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  .title{
    width: 100%;
    // height: 30px;
    color: white;
    font-size: 18px;
    align-content: center;
    position: relative;
    margin-top: 30px;
    z-index: 99;
    img{
      width: 65%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    span{
      margin-top: -2px;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 16px;
      transform: translate(-50%,-50%);
    }
  }
  .dscription{
    margin-top: 30px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 16px;
    .vipRank{
      margin-left: 5px;
    }
  }
  .tableData{
    width: 100%;
    height: auto;
    margin-top: 30px;

    .t1{
      ul{
        display: flex;
        color: white;
        justify-content: space-around;
        width: 100%;
        height: 35px;
        background: linear-gradient(180deg, rgba(82, 0, 255, 0) 0%, rgba(0, 22, 224, .2) 100%);
        border: .02667rem solid rgba(169, 72, 250, .2);
        li{
          flex: 1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .content{
      .bg1{
          background: linear-gradient(90deg, #1c2248 0%, rgba(21, 25, 54, 0) 100%);
        }
        .bg2{
          background: rgba(18, 20, 41, .35);
        }
      ul{
        color: white;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-around;
        background: linear-gradient(90deg, #1c2248 0%, rgba(21, 25, 54, 0) 100%);
        }
        li{
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: space-around;
          align-items: center;
      }
    }
  }
}
</style>
